import React from "react";
import { Button, CardContent, IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import styles from "../../styles/Threads.module.css";
import { InView } from "react-intersection-observer";
import SeenBy from "../others/SeenBy";
import BodyContent from "./BodyContent";
import EditIcon from "@mui/icons-material/Edit";
import fotoPerfil from "../../assets/perfilionvo.png";
import zIndex from "@mui/material/styles/zIndex";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Comments = ({
  i,
  indexComment,
  handleFormDeleteComment,
  handleSeen,
  index,
  handleMouseDeleteComment,
  handleCommentResponse,
  handleImageClick,
  handleMouseDeleteCommenLeave,
  handleMouseLeftSeenComment,
  imgRefs,
  userId,
  key,
  commentID,
  showDeleteIcon,
  formattedDate,
  mouseOverComment,
  positionDeleteComment,
  contenidoRef,
  handleMouseOnSeenComment,
  positionSeenComment,
  handleFormSubmitLike,
  messageType,
  handleFormEditComment,
  editCommentForm,
  setEditCommentForm,
  editingCommentId,
  setEditingCommentId,
  row,
  setSelectedUsers,
  setSelectedGroup,
  selectedUsers,
  selectedGroup,
  commentWritten,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <img
        src={
          i?.user?.avatar?.url
            ? `${backendUrl}${i?.user?.avatar?.url}`
            : fotoPerfil
        }
        className={styles.userImgComment}
        alt="Perfil"
      />

      <div
        className={styles.commentContainer}
        style={{ width: editCommentForm ? "100%" : "" }}
      >
        <CardContent
          className={styles.commentContent}
          sx={{
            padding: "10px 16px",
            width: editCommentForm ? "100%" : "fit-content",
          }}
          onMouseOver={() => handleMouseDeleteComment(index, i)}
          onMouseLeave={handleMouseDeleteCommenLeave}
        >
          {showDeleteIcon &&
          positionDeleteComment === index &&
          +i?.user?.id === +userId &&
          +i?.id === +commentID ? (
            <div style={{ position: "absolute", right: "0", top: "-20px" }}>
              <Tooltip title="Editar">
                <IconButton
                  onClick={() => handleFormEditComment(i.id)}
                  sx={{
                    boxShadow: 8,
                    marginRight: "8px",
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                <IconButton
                  onClick={() => handleFormDeleteComment(i.id)}
                  sx={{
                    boxShadow: 8,
                  }}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
          <div className={styles.commentWrap} id={messageType + i.id}>
            <div className={styles.user}>
              <h4 className={styles.usernameComment}>{i?.user?.username}</h4>
            </div>
          </div>
          <InView
            key={i.id}
            threshold={1}
            onChange={(inView) => {
              if (inView) {
                handleSeen(i, messageType);
              }
            }}
          >
            {({ ref }) => (
              <div ref={ref}>
                <BodyContent
                  contenidoRef={contenidoRef}
                  imgRefs={imgRefs}
                  message={i}
                  handleImageClick={handleImageClick}
                  messageType={messageType}
                  editCommentForm={editCommentForm}
                  setEditCommentForm={setEditCommentForm}
                  editingCommentId={editingCommentId}
                  setEditingCommentId={setEditingCommentId}
                  row={row}
                  setSelectedUsers={setSelectedUsers}
                  setSelectedGroup={setSelectedGroup}
                  selectedUsers={selectedUsers}
                  selectedGroup={selectedGroup}
                  commentWritten={commentWritten}
                />
              </div>
            )}
          </InView>
        </CardContent>

        <div
          style={{
            display: "flex",
            marginTop: "8px",
          }}
        >
          <div className={styles.commentBottomWrap}>
            <ScheduleIcon
              style={{
                marginRight: "4px",
                color: "var(--gray)",
                fontSize: "18px",
              }}
            />
            <p className={styles.dateComment}>{formattedDate}</p>
          </div>
          <span className={styles.divisionLineComment}></span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              color: "var(--gray)",
              fontSize: "16px",
              position: "relative",
              cursor: "default",
            }}
            onMouseOver={() => handleMouseOnSeenComment(index, i, indexComment)}
            onMouseLeave={handleMouseLeftSeenComment}
          >
            {mouseOverComment &&
            +positionSeenComment === +index &&
            +i.id === +commentID ? (
              <div style={{ zIndex: "9999" }}>
                <SeenBy
                  seenby={[i?.seen_by]}
                  key={{ key }}
                  backendUrl={{
                    backendUrl,
                  }}
                  id="position"
                  style={{
                    left: "400px",
                  }}
                />{" "}
              </div>
            ) : null}
            <VisibilityOutlinedIcon
              sx={{
                fontSize: "18px",
                margin: "0 5px 0 10px",
              }}
            />
            {i?.seen_by?.length}
          </div>
          <span className={styles.divisionLineComment}></span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              borderBottomLeftRadius: "8px",
              marginRight: i.likes.length > 0 ? "10px" : "0px",
              color: "var(--gray)",
            }}
          >
            <ThumbUpAltOutlinedIcon
              style={{
                margin: i.likes.length === 0 ? "0 10px" : "0 5px 0 10px",
                color: i.likes.length > 0 ? "var(--blueTwo)" : "var(--gray)",
                cursor: "pointer",
                fontSize: "18px",
              }}
              onClick={() => handleFormSubmitLike(null, i.id)}
            />

            {i.likes.length > 0 ? i.likes.length : null}
          </div>

          <span className={styles.divisionLineComment}></span>

          <div className={styles.commentResponse}>
            <Button
              style={{
                backgroundColor: "transparent",
                textTransform: "capitalize",
                fontSize: "16px",
                padding: "0 12px",
                width: "100%",
                height: "100%",
                fontWeight: "400",
                color: "var(--gray)",
              }}
              onClick={(e) => handleCommentResponse(e, index, indexComment)}
            >
              Responder
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
