export const menuItems = [
  {
    name: "ALUNA",
    roles: ["Authenticated", "aluna", "aluna-management", "aluna-comercial", "aluna-branding"],
    item: [
      {
        title: "MASTER CPL - CPA - CPI",
        to: "/master-performance",
        roles: ["Authenticated", "aluna", "aluna-management"],
      },
      {
        title: "MASTER CPS",
        to: "/master-cps",
        roles: ["Authenticated", "aluna", "aluna-management"],
      },
      {
        title: "MASTER BRANDING",
        to: "/master-branding",
        roles: ["Authenticated", "aluna", "aluna-management", "aluna-branding"],
      },
      {
        title: "TASKS ALUNA",
        to: "/tasks-aluna",
        roles: ["Authenticated", "aluna", "aluna-management"],
      },
      {
        title: "ALERTAS",
        to: "/alertsAluna",
        roles: ["Authenticated", "aluna", "aluna-management"],
      },
      {
        title: "CSM",
        roles: ["Authenticated", "aluna", "aluna-management"],
        subtitle: [
          {
            subTitle: "VALIDACIONES / CIERRE ALUNA",
            to: "/validaciones-cierre-aluna",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "CREAR CAMPAÑA",
            to: "campaign-creator",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "EDITAR CAMPAÑA",
            to: "edit-campaign",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "VALIDAR CONVERSIONES",
            to: "validar-conversiones",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "CREAR CONVERSIONES",
            to: "creacion-de-conversiones",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
        ],
      },
      {
        title: "AFILIACION",
        roles: ["Authenticated", "aluna", "aluna-management"],
        subtitle: [
          {
            subTitle: "SOLICITUD DE CAMPAÑAS NUEVA ALUNA",
            to: "/solicitud-campana",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "PROSPECTING AFILIADOS",
            to: "/prospecting-afiliado",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "INFORMACIÓN DE TRÁFICO DE AFILIADOS",
            to: "/info-trafico-afiliado",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "PANEL DE CAPS",
            to: "panel-de-caps",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "SEGUIMIENTO DE CAMPAÑA",
            to: "seguimiento-diario",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
          {
            subTitle: "SEGUIMIENTO AFILIADO",
            to: "seguimiento-afiliado",
            roles: ["Authenticated", "aluna", "aluna-management"],
          },
        ],
      },
      {
        title: "COMERCIAL",
        roles: ["Authenticated", "aluna-management", "aluna-comercial"],
        subtitle: [
          {
            subTitle: "CRM",
            to: "/crm",
            roles: ["Authenticated", "aluna-management", "aluna-comercial"],
          },
          {
            subTitle: "PROSPECTING",
            to: "/prospecting",
            roles: ["Authenticated", "aluna-management", "aluna-comercial"],
          },
        ],
      },
    ],
  },

  {
    name: "ROUNDLER",
    roles: ["Authenticated", "roundler-admin"],
    item: [
      {
        title: "MASTER TAG",
        to: "/master-tag-roundler",
        roles: ["Authenticated", "roundler-admin"],
      },
    ],
  },
  {
    name: "HOP",
    roles: ["Authenticated", "hop",  "hop-management"],
    item: [
      {
        title: "ALERTAS",
        to: "/hop-alerts",
        roles: ["Authenticated", "hop",  "hop-management"],
      },
    ],
  },
  {
    name: "IONVO",
    roles: ["Authenticated", "roundler-admin", "aluna", "aluna-management",  "hop-management"],
    item: [
      {
        title: "PEDIDOS IT",
        to: "/it-request",
        roles: ["Authenticated", "roundler-admin", "aluna", "aluna-management",  "hop-management"],
      },
    ],
  },
];
