import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import NavBar from "./components/global/NavBar";
import SideBar from "./components/global/SideBar.jsx";
import Home from "./pages/Home";
import Landing from "./components/global/Landing";
import AlunaMasterPerformance from "./pages/BusinessUnit/aluna/boards/AlunaMasterPerformance";
import AlunaAlerts from "./pages/BusinessUnit/aluna/boards/AlunaAlerts";
import AlunaMasterCps from "./pages/BusinessUnit/aluna/boards/AlunaMasterCps";
import PedidosIt from "./pages/BusinessUnit/ionvo/boards/PedidosIt";
import { ThreadContext } from "./threadContext";
import { useState } from "react";
import { UserContext } from "./UserContext";
import LoginRedirect from "./pages/others/LoginRedirect";
import CampaignCreator from "./pages/CampaignCreator/CampaignCreator";

import CheckSession from "./utils/CheckSession";
import EditCampaign2 from "./pages/BusinessUnit/aluna/EditCampaign2";

import PanelDeCaps from "./pages/BusinessUnit/aluna/boards/PanelDeCaps";

import { LicenseInfo } from "@mui/x-license-pro";
import TasksAluna from "./pages/BusinessUnit/aluna/boards/TasksAluna";
import Validaciones from "./pages/BusinessUnit/aluna/boards/Validaciones";
import ProspectingAfiliados from "./pages/BusinessUnit/aluna/boards/ProspectingAfiliados";
import InfoTraficoAfiliado from "./pages/BusinessUnit/aluna/boards/InfoTraficoAfiliado";
import SolicitudCampana from "./pages/BusinessUnit/aluna/boards/SolicitudCampana.jsx";
import SeguimientoCampana from "./pages/BusinessUnit/aluna/boards/SeguimientoCampana.jsx";
import SeguimientoCampana2 from "./pages/BusinessUnit/aluna/boards/SeguimientoCampana2.jsx";

import MasterTag from "./pages/BusinessUnit/roundler/boards/MasterTag/MasterTag.jsx";
import HomeDashBoard from "./pages/BusinessUnit/aluna/dashboards/HomeDashBoard.jsx";
import SeguimientoAfiliado from "./pages/BusinessUnit/aluna/boards/SeguimientoAfiliado";
import TestCreateBoard from "./pages/global/TestCreateBoard";
import ValidarConversiones from "./pages/BusinessUnit/aluna/boards/ValidarConversiones";
import AdminPanel from "./pages/Admin/AdminPanel";
import Employees from "./pages/Admin/Employees";
import axios from "axios";
import { useEffect } from "react";
import CreacionDeConversiones from "./pages/BusinessUnit/aluna/boards/CreacionDeConversiones";
import Swal from "sweetalert2";
import AlunaMasterBranding from "./pages/BusinessUnit/aluna/boards/AlunaMasterBranding";
import Prospecting from "./pages/BusinessUnit/aluna/boards/Prospecting";
import CRM from "./pages/BusinessUnit/aluna/boards/CRM";
import HopAlerts from "./pages/BusinessUnit/hop/boards/HopAlerts.jsx";

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(
  "c4a01d9c2175e4c9793f1ccf7b3d7285Tz03MDAwOSxFPTE3MjAwNDU3OTcyMjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function App() {
  const [key, setKey] = useState(null);

  // const { contextKey, setContextKey } = useContext(UserContext);

  const [theme, colorMode] = useMode();
  const [thread, setThread] = useState(null);

  function MySideBar() {
    const element = useRoutes([
      {
        path: "/",
        element: () => null, // retorna null para excluir la ruta
      },
      {
        path: "*",
        element: <SideBar />,
      },
    ]);
    return element;
  }

  function MyNavBar() {
    const element = useRoutes([
      {
        path: "/",
        element: () => null, // retorna null para excluir la ruta
      },
      {
        path: "*",
        element: <NavBar />,
      },
    ]);
    return element;
  }

  const PrivateRoutes = () => {
    const [isLogged] = useState(!!localStorage.getItem("jwt"));

    return isLogged ? <Outlet /> : <Navigate to="/" />;
  };

  const navigate = useNavigate();

  let lsrole = localStorage.getItem("role");

  const checkToken = async () => {
    const id = localStorage.getItem("id");

    const userData = await axios.get(
      `${backendUrl}/api/users/${id}?populate=role`,
      {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      }
    );

    if (userData.data.role.name !== lsrole) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("id");
      localStorage.removeItem("session");
      localStorage.removeItem("role");
      Swal.fire(
        "Su sesión expiró, por favor ingresar nuevamente",
        "",
        "info"
      ).then(navigate(`/`, { replace: true }));
    }
  };

  useEffect(() => {
    if (key) {
      checkToken();
    }
  }, [key]);

  // useEffect(() => {
  //   if (isLoggedIn()) {
  //     navigate("/home");
  //   }
  // }, [navigate]);

  const session = localStorage?.getItem("session");

  CheckSession(session);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <MySideBar />
          <main className="content">
            <MyNavBar />
            <UserContext.Provider value={{ key, setKey }}>
              <ThreadContext.Provider value={{ thread, setThread }}>
                <Routes>
                  <Route
                    path="/test-create-board"
                    element={<TestCreateBoard />}
                  />
                  <Route exact path="/" element={<Landing />} />
                  <Route
                    path="/connect/:providerName/redirect"
                    element={<LoginRedirect />}
                  />
                  <Route element={<PrivateRoutes />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/dashboard" element={<HomeDashBoard />} />
                    <Route
                      path="/master-performance"
                      element={<AlunaMasterPerformance />}
                    />
                    <Route
                      path="/master-branding"
                      element={<AlunaMasterBranding />}
                    />
                    <Route path="/tasks-aluna" element={<TasksAluna />} />

                    <Route
                      path="/master-branding"
                      element={<AlunaMasterBranding />}
                    />

                    <Route
                      path="/validaciones-cierre-aluna"
                      element={<Validaciones />}
                    />
                    <Route
                      path="/prospecting-afiliado"
                      element={<ProspectingAfiliados />}
                    />
                    <Route
                      path="/info-trafico-afiliado"
                      element={<InfoTraficoAfiliado />}
                    />
                    <Route
                      path="/solicitud-campana"
                      element={<SolicitudCampana />}
                    />
                    <Route path="/master-cps" element={<AlunaMasterCps />} />
                    <Route path="/it-request" element={<PedidosIt />} />
                    <Route
                      path="/seguimiento-diario"
                      element={<SeguimientoCampana />}
                    />
                    <Route
                      path="/seguimiento-campana2"
                      element={<SeguimientoCampana2 />}
                    />
                    <Route
                      path="/seguimiento-afiliado"
                      element={<SeguimientoAfiliado />}
                    />
                    <Route path="/alertsAluna" element={<AlunaAlerts />} />
                    <Route
                      path="/campaign-creator"
                      element={<CampaignCreator />}
                    />
                    <Route path="/edit-campaign" element={<EditCampaign2 />} />

                    <Route path="/edit-campaign2" element={<EditCampaign2 />} />
                    <Route path="/panel-de-caps" element={<PanelDeCaps />} />
                    <Route
                      path="/master-tag-roundler"
                      element={<MasterTag />}
                    />
                    <Route
                      path="/validar-conversiones"
                      element={<ValidarConversiones />}
                    />

                    <Route path="/admin" element={<AdminPanel />} />
                    <Route
                      path="/admin/users-and-roles"
                      element={<Employees />}
                    />
                    <Route
                      path="/creacion-de-conversiones"
                      element={<CreacionDeConversiones />}
                    />
                    <Route path="/prospecting" element={<Prospecting />} />
                    <Route path="/crm" element={<CRM />} />

                    {/* <Route path="/hop-metrics" element={<Metrics />} /> */}
                    <Route path="/hop-alerts" element={<HopAlerts />} />
                  </Route>
                </Routes>
              </ThreadContext.Provider>
            </UserContext.Provider>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
