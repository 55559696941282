import axios from "axios";
import dompurify from "dompurify";
import dayjs from "dayjs";
import "dayjs/locale/es";
import FormData from "form-data";
import { sortAlphabetically } from "../json/CampaignCreator";

const headersJsonType = () => {
    const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage?.getItem("jwt")}`,
    };
    return headers;
};

const headersList = () => {
    const headers = {
        Accept: "*/*",
        Authorization: `Bearer ${localStorage?.getItem("jwt")}`,
    };
    return headers;
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const filteredSelectedItems = (items, content) => {
    const filteredSelected = items.filter((item) =>
        content.includes(`data-id="${item.id}"`)
    );
    return filteredSelected;
};
export const editBoardField = async (u, selectionRow, f, v) => {
    if (u === "/master-branding" && v === "Sí") {
        v = true;
    } else if (u === "/master-branding" && v === "No") {
        v = false;
    }

    try {
        const requests = selectionRow.map(async (selectId) => {
            const reqOptions = {
                url: `${backendUrl}/api${u}/edit/${selectId}`,
                method: "PUT",
                headers: headersList(),
                data: { data: { [f]: v } },
            };

            try {
                const response = await axios.request(reqOptions);
                return response.data.data;
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    return error.response;
                }
                throw new Error(
                    error.response
                        ? error.response.data.error.message
                        : "Error desconocido"
                );
            }
        });

        const results = await Promise.all(requests);
        return results;
    } catch (error) {
        throw new Error(`Error in editBoardField: ${error.message}`);
    }
};
export const fetchData = async (a) => {
    try {
        const headers = headersList();
        return (await axios.get(a, { headers })).data;
    } catch (a) {
        throw new Error(`Failed to fetch data: ${a.message}`);
    }
};

export const getThreads = async (url, id, headers) => {
    const reqOptions = {
        url: `${backendUrl}/api/item-service/select/${id}?board=${url}`,
        method: "GET",
        headers,
    };

    const response = await axios.request(reqOptions);
    return response.data;
};

export const handleSubmitThread = async (
    pathname,
    key,
    file,
    row,
    titleNewThread,
    valueWritten,
    selectedUsers,
    selectedGroup
) => {
    if (
        file.length === 0 &&
        valueWritten === "<p><br></p>" &&
        titleNewThread === ""
    ) {
        return;
    }

    let mediaIds;
    let mediaUrls;
    if (file.length !== 0) {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        const uploadFile = await fetch(`${backendUrl}/api/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${key}` },
        });

        const uploadRes = await uploadFile.json();
        mediaIds = Array.isArray(uploadRes)
            ? uploadRes.map((file) => file.id)
            : [];
        mediaUrls = Array.isArray(uploadRes)
            ? uploadRes.map((file) => `${backendUrl}${file.url}`)
            : [];
    }

    const newContent = dompurify.sanitize(valueWritten, { FORCE_BODY: true });

    let data;

    const filteredSelectedUsers = filteredSelectedItems(
        selectedUsers,
        valueWritten
    );
    const filteredSelectedGroup = filteredSelectedItems(
        selectedGroup,
        valueWritten
    );

    const board = pathname.replace(/-/g, "_");
    data = {
        content: newContent === "<p><br></p>" ? null : newContent,
        title: `${titleNewThread}`,
        [board]: row.id,
        media: mediaIds,
        urls: mediaUrls,
        users: filteredSelectedUsers,
        group: filteredSelectedGroup,
    };

    let reqOptions = {
        url: `${backendUrl}/api/newThread?board=${pathname}`,
        method: "POST",
        headers: headersJsonType(),
        data: JSON.stringify(data),
    };

    let response = await axios.request(reqOptions);
    return response;
};

export const handleDeleteThread = async (pathname, thread, row) => {
    let reqOptions = {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/threads/${thread.id}?board=${pathname}&item=${row.id}`,
        method: "DELETE",
        headers: headersList(),
    };

    let response = await axios.request(reqOptions);
    return response;
};

export const handleDeleteComment = async (pathname, commentID, row) => {
    let reqOptions = {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/comments/${row.id}?board=${pathname}&comment=${commentID}`,
        method: "DELETE",
        headers: headersList(),
    };

    let response = await axios.request(reqOptions);
    return response;
};

export const handleSubmitComment = async (
    pathname,
    id,
    key,
    file,
    row,
    commentWritten,
    selectedUsers,
    selectedGroup
) => {
    if (file.length === 0 && commentWritten === "") {
        return;
    }
    let mediaIds;
    let mediaUrls;
    if (file.length !== 0) {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        const uploadFile = await fetch(`${backendUrl}/api/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${key}` },
        });

        const uploadRes = await uploadFile.json();
        mediaIds = Array.isArray(uploadRes)
            ? uploadRes.map((file) => file.id)
            : [];
        mediaUrls = Array.isArray(uploadRes)
            ? uploadRes.map((file) => `${backendUrl}${file.url}`)
            : [];
    }

    const filteredSelectedUsers = filteredSelectedItems(
        selectedUsers,
        commentWritten
    );
    const filteredSelectedGroup = filteredSelectedItems(
        selectedGroup,
        commentWritten
    );

    const newComment = dompurify.sanitize(commentWritten, {
        FORCE_BODY: true,
    });

    let data = {
        content: newComment === "<p><br></p>" ? null : newComment,
        thread: id,
        media: mediaIds,
        urls: mediaUrls,
        users: filteredSelectedUsers,
        group: filteredSelectedGroup,
    };

    let reqOptions = {
        url: `${backendUrl}/api/newComment/${row.id}?board=${pathname}`,
        method: "POST",
        headers: headersJsonType(),
        data: JSON.stringify(data),
    };

    let response = await axios.request(reqOptions);

    return response;
};

export const handleSubmitLikes = async (pathname, thread, comment, row) => {
    let bodyContent;
    if (thread !== null) {
        bodyContent = JSON.stringify({
            comment: null,
            thread: thread,
        });
    } else {
        bodyContent = JSON.stringify({
            comment: comment,
            thread: null,
        });
    }

    let reqOptions = {
        url: `${backendUrl}/api/like/${row.id}?board=${pathname}`,
        method: "POST",
        headers: headersJsonType(),
        data: bodyContent,
    };

    let response = await axios.request(reqOptions);
    return response.data.threads;
};

export const putAnchor = async (anchored, idThread, pathname, idItem) => {
    if (anchored !== true) {
        anchored = false;
    }

    let bodyContent = JSON.stringify({
        anchored: !anchored,
        id: idThread,
    });

    let reqOptions = {
        url: `${backendUrl}/api/anchor/${idItem}?board=${pathname}`,
        method: "PUT",
        headers: headersJsonType(),
        data: bodyContent,
    };

    let response = await axios.request(reqOptions);
    return response;
};

export const putSeen = async (id, pathname, typeContent, idItem) => {
    let reqOptions = {
        url: `${backendUrl}/api/seen/${typeContent}/${idItem}?board=${pathname}&id=${id}`,
        method: "PUT",
        headers: headersJsonType(),
    };

    let response = await axios.request(reqOptions);
    return response;
};

export const postOfferAffise = async (
    dataForm,
    caps,
    landings,
    bat,
    direct
) => {
    const formattedDateStart = dayjs(dataForm.dateRange[0].startDate).format(
        "YYYY-MM-DD"
    );
    const formattedDateTo = dayjs(dataForm.dateRange[0].endDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

    let bodyContent = JSON.stringify({
        board: dataForm.board,
        offerTitle: dataForm.offerTitle,
        advertiser: dataForm.advertisers,
        targeting: dataForm.descriptionTargeting,
        textosCreativos: dataForm.descriptionCreatives,
        sources: dataForm.trafficSourcesAffise,
        status: dataForm.status,
        tags: dataForm.tags,
        dateStart: formattedDateStart,
        dateTo: formattedDateTo,
        categories: dataForm.categories,
        trackingDomain: dataForm.domains,
        previewUrl: dataForm.previewUrl,
        url: dataForm.trackingUrl,
        targetingCountry: dataForm.countriesTargeting,
        paymentCountry: dataForm.countriesPayout,
        paymentType: dataForm.paymentType,
        paymentPayouts: dataForm.payouts,
        paymentRevenue: dataForm.revenue,
        currency: dataForm.currencies,
        paymentGoal: dataForm.goalValue,
        paymentGoalTitle: dataForm.goalTitle,
        cap: caps,
        landings: landings,
        typeClient: dataForm.typeOfClient,
        encargadaCSM: dataForm.encargadaCsm,
        monedaAnunciante: dataForm.monedaAnunciante,
        revenueMAnunciante: dataForm.revenueMAnunciante,
        BAT: bat,
        other: dataForm.other,
        estimadoCapMensual: dataForm.capEstimado,
        payoutAceptado: dataForm.payoutAceptado,
        pixelStatus: dataForm.pixelStatus,
        conversionTypeKALA: dataForm.conversionTypeKALA,
        privacy: dataForm.privacy,
        ticketPromedio: dataForm.ticketPromedio,
        responsible: dataForm.responsible,
        owner: dataForm.owner,
        format: dataForm.format,
        platform: dataForm.platform,
        typeOfContract: dataForm.typeOfContract,
        segmentation: dataForm.segmentation,
        billingMonth: dataForm.billingMonth,
        test: dataForm.test,
        offerStatus: dataForm.offerStatus,
        revenueUSD: dataForm.revenueUSD,
        payoutUSD: dataForm.payoutUSD,
        trafficSources: dataForm.trafficSources,
    });

    let reqOptions = {
        url: `${backendUrl}/api/offer-service/postOffer`,
        method: "POST",
        headers: headersJsonType(),
        data: bodyContent,
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const editOfferAffise = async (dataOffer) => {
    const dateStart = dataOffer.hasOwnProperty("dateStart")
        ? dataOffer.dateStart
        : "";
    const dateTo = dataOffer.hasOwnProperty("dateTo") ? dataOffer.dateTo : "";
    const formattedDateStart = dayjs(dateStart).format("YYYY-MM-DD");
    const formattedDateTo = dayjs(dateTo).format("YYYY-MM-DD");

    let bodyContent = JSON.stringify({
        personalPayout: dataOffer.hasOwnProperty("personalPayout")
            ? dataOffer.personalPayout
            : [],
        personalTargeting: dataOffer.hasOwnProperty("personalTargeting")
            ? dataOffer.personalTargeting
            : [],
        offerTitle: dataOffer.hasOwnProperty("offerTitle")
            ? dataOffer.offerTitle
            : "",
        advertiser: dataOffer.hasOwnProperty("advertiser")
            ? dataOffer.advertiser
            : "",
        description: dataOffer.hasOwnProperty("description")
            ? dataOffer.description
            : "",
        targeting: dataOffer.hasOwnProperty("targeting")
            ? dataOffer.targeting
            : "",
        sources: dataOffer.hasOwnProperty("sources") ? dataOffer.sources : [],
        status: dataOffer.hasOwnProperty("status") ? dataOffer.status : "",
        tags: dataOffer.hasOwnProperty("tags") ? dataOffer.tags : [],
        dateStart: formattedDateStart,
        dateTo: formattedDateTo,
        categories: dataOffer.hasOwnProperty("categories")
            ? dataOffer.categories
            : [],
        trackingDomain: dataOffer.hasOwnProperty("trackingDomain")
            ? dataOffer.trackingDomain
            : "",
        previewUrl: dataOffer.hasOwnProperty("previewUrl")
            ? dataOffer.previewUrl
            : "",
        url: dataOffer.hasOwnProperty("url") ? dataOffer.url : "",
        targetingCountry: dataOffer.hasOwnProperty("targetingCountry")
            ? dataOffer.targetingCountry
            : [],
        paymentCountry: dataOffer.hasOwnProperty("paymentCountry")
            ? dataOffer.paymentCountry
            : [],
        paymentType: dataOffer.hasOwnProperty("paymentType")
            ? dataOffer.paymentType
            : "",
        paymentPayouts: dataOffer.hasOwnProperty("paymentPayouts")
            ? dataOffer.paymentPayouts
            : "",
        paymentRevenue: dataOffer.hasOwnProperty("paymentRevenue")
            ? dataOffer.paymentRevenue
            : "",
        currency: dataOffer.hasOwnProperty("currency")
            ? dataOffer.currency
            : "",
        paymentGoal: dataOffer.hasOwnProperty("paymentGoal")
            ? dataOffer.paymentGoal
            : "",
        paymentGoalTitle: dataOffer.hasOwnProperty("paymentGoalTitle")
            ? dataOffer.paymentGoalTitle
            : "",
        typeClient: dataOffer.hasOwnProperty("typeClient")
            ? dataOffer.typeClient
            : "",
        encargadaCSM: dataOffer.hasOwnProperty("encargadaCSM")
            ? dataOffer.encargadaCSM
            : "",
        monedaAnunciante: dataOffer.hasOwnProperty("monedaAnunciante")
            ? dataOffer.monedaAnunciante
            : "",
        revenueMonedaAnunciante: dataOffer.hasOwnProperty(
            "revenueMonedaAnunciante"
        )
            ? dataOffer.revenueMonedaAnunciante
            : "",
        BAT: dataOffer.hasOwnProperty("BAT") ? dataOffer.BAT : "",
        other: dataOffer.hasOwnProperty("other") ? dataOffer.other : "",
        id: dataOffer.hasOwnProperty("id") ? dataOffer.id : "",
        payoutAceptado: dataOffer.hasOwnProperty("payoutAceptado")
            ? dataOffer.payoutAceptado
            : "",
        estimadoCapMensual: dataOffer.hasOwnProperty("estimadoCapMensual")
            ? dataOffer.estimadoCapMensual
            : "",
        privacy: dataOffer.hasOwnProperty("privacy") ? dataOffer.privacy : "",
        landing: dataOffer.hasOwnProperty("landing") ? dataOffer.landing : "",
    });

    let reqOptions = {
        url: `${backendUrl}/api/offer-service/editOffer`,
        method: "PUT",
        headers: headersJsonType(),
        data: bodyContent,
    };
    await axios.request(reqOptions);
};

export const editDate = async (url, dateStart, dateTo, ids) => {
    let formattedDateStart = "";
    let formattedDateTo = dayjs(dateTo).add(1, "day").format("YYYY-MM-DD");
    if (dateStart !== "") {
        formattedDateStart = dayjs(dateStart).format("YYYY-MM-DD");
    }

    if (url === "inhouse-media-buying" || url === "validaciones") {
        const bodyContent = {
            dateStart: formattedDateStart,
            dateTo: formattedDateTo,
        };

        const reqOptions = {
            url: `${backendUrl}/api/${url}/edit/${ids}`,
            method: "PUT",
            headers: headersJsonType(),
            data: { data: bodyContent },
        };
        const response = await axios.request(reqOptions);
        return response;
    } else if (
        url === "prospecting-afiliado" ||
        url === "solicitud-campana" ||
        url === "prospecting" ||
        url === "crm"
    ) {
        let bodyContent = {
            date: formattedDateStart,
        };

        if (url === "prospecting" || url === "crm") {
            bodyContent = {
                reminder: formattedDateStart,
            };
        }

        const reqOptions = {
            url: `${backendUrl}/api/${url}/edit/${ids}`,
            method: "PUT",
            headers: headersJsonType(),
            data: { data: bodyContent },
        };
        const response = await axios.request(reqOptions);
        return response;
    } else {
        const bodyContent = JSON.stringify({
            dateStart: formattedDateStart,
            dateTo: formattedDateTo,
            board: url,
            rowIds: ids,
        });
        const reqOptions = {
            url: `${backendUrl}/api/offer-service/editDate`,
            method: "PUT",
            headers: headersJsonType(),
            data: bodyContent,
        };
        const response = await axios.request(reqOptions);
        return response;
    }
};

export const postCapAffise = async (value, timeframe, affiliate, offerID) => {
    let bodyContent = JSON.stringify({
        value,
        timeframe,
        affiliates: affiliate,
    });

    let reqOptions = {
        url: `${backendUrl}/api/offer-service/editCap/${offerID}`,
        method: "PUT",
        headers: headersJsonType(),
        data: bodyContent,
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const postFile = async (files, id, url, type, creativesLength) => {
    // PROBAR CUANDO NO TIENE NINGUN CREATIVE
    let creativeIndex = creativesLength;

    const upload = async (file, index) => {
        let bodyContent = new FormData();
        bodyContent.append(type, file);

        try {
            let response = await fetch(
                `${backendUrl}/api/offer-service/${url}/${id}?index=${index}`,
                {
                    method: "POST",
                    body: bodyContent,
                    headers: headersList(),
                }
            );
            let data = await response.text();
            return data;
        } catch (error) {
            return error;
        }
    };
    if (files === "") {
        return null;
    }
    if (files.length > 0) {
        files.map(async (file, i) => {
            let index = creativeIndex + i;
            await upload(file, index);
        });
    } else {
        await upload(files, 0);
    }
};

export const postReporteInvalidacion = async (
    key,
    file,
    id,
    pathname,
    typeColumn,
    field
) => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
    }

    const uploadFile = await fetch(`${backendUrl}/api/upload`, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${key}` },
    });

    const uploadRes = await uploadFile.json();

    let mediaIds = Array.isArray(uploadRes)
        ? uploadRes.map((file) => file.id)
        : [];

    let reqOptions = {
        url: `${backendUrl}/api${pathname}/${typeColumn}/${id}`,
        method: "PUT",
        headers: headersJsonType(),
        data: JSON.stringify({ mediaIds, field }),
    };
    let response = await axios.request(reqOptions);
    await postActivityLogFiles(
        id,
        pathname,
        "reporteInvalidacion",
        file[0].name,
        "posted"
    );
    return response;
};

export const postMediaItem = async (key, file, id, board) => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
    }

    const uploadFile = await fetch(`${backendUrl}/api/upload`, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${key}` },
    });

    const uploadRes = await uploadFile.json();

    let mediaIds = Array.isArray(uploadRes)
        ? uploadRes.map((file) => file.id)
        : [];

    let reqOptions = {
        url: `${backendUrl}/api/postMedia/${id}`,
        method: "PUT",
        headers: headersJsonType(),
        data: JSON.stringify({ mediaIds, board }),
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const deleteReporteInvalidacion = async (
    fileID,
    id,
    pathname,
    deleteTypeColumn,
    field
) => {
    let reqOptions = {
        url: `${backendUrl}/api${pathname}/${deleteTypeColumn}/${id}`,
        method: "PUT",
        headers: headersJsonType(),
        data: JSON.stringify({ mediaIds: fileID, field }),
    };

    const getFileName = async () => {
        // /api/upload/files/
        let reqOptions = {
            url: `${backendUrl}/api/upload/files/${fileID}`,
            method: "GET",
            headers: headersJsonType(),
        };
        let response = await axios.request(reqOptions);
        return response.data.name;
    };
    const fileName = await getFileName();

    let response = await axios.request(reqOptions);

    await postActivityLogFiles(
        id,
        pathname,
        "reporteInvalidacion",
        fileName,
        "deleted"
    );

    return response;
};

export const deleteMediaItem = async (fileID, id, board) => {
    let reqOptions = {
        url: `${backendUrl}/api/deleteMedia/${id}`,
        method: "PUT",
        headers: headersJsonType(),
        data: JSON.stringify({ mediaIds: fileID, board }),
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const handleSubmitNote = async (
    key,
    file,
    board,
    id,
    content,
    title
) => {
    let mediaIds;
    if (file) {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        const uploadFile = await fetch(`${backendUrl}/api/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${key}` },
        });

        const uploadRes = await uploadFile.json();

        mediaIds = Array.isArray(uploadRes)
            ? uploadRes.map((file) => file.id)
            : [];
    }
    const newContent = dompurify.sanitize(content, { FORCE_BODY: true });

    let data = {
        content: newContent === "<p><br></p>" ? null : newContent,
        media: mediaIds,
        title,
    };

    let reqOptions = {
        url: `${backendUrl}/api/notes/${id}?board=${board}`,
        method: "POST",
        headers: headersJsonType(),
        data,
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const handleDeleteNote = async (
    key,
    pathname,
    strapiID,
    noteID,
    setNotes
) => {
    let reqOptions = {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/notes/${strapiID}?note=${noteID}&board=${pathname}`,
        method: "DELETE",
        headers: headersList(),
    };

    let response = await axios.request(reqOptions);
    let newNotes = response.data.notes;

    setNotes(newNotes);
};

export const handleEditNote = async (
    files,
    board,
    strapiID,
    idNote,
    content,
    setNotes
) => {
    let data = {
        idNote,
        content,
    };

    let reqOptions = {
        url: `${backendUrl}/api/notes/${strapiID}?board=${board}`,
        method: "PUT",
        headers: headersList(),
        data,
    };

    let response = await axios.request(reqOptions);
    let newNotes = response.data.notes;

    setNotes(newNotes);
};

export const editThreadForm = async (
    key,
    idThread,
    content,
    board,
    id,
    file,
    media
) => {
    let mediaIds;

    if (file) {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        const uploadFile = await fetch(`${backendUrl}/api/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${key}` },
        });

        const uploadRes = await uploadFile.json();

        mediaIds = Array.isArray(uploadRes)
            ? uploadRes.map((file) => file.id)
            : [];
        if (media) {
            media = media.map((e) => e.id);
            mediaIds = mediaIds.concat(media);
        }
    } else {
        mediaIds = media.map((e) => e.id);
    }

    let data = {
        idThread,
        content,
        mediaIds,
    };
    let reqOptions = {
        url: `${backendUrl}/api/thread/${id}?board=${board}`,
        method: "PUT",
        headers: headersJsonType(),
        data,
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const editComment = async (
    // TODO acá hay que agregarle el mediaids
    key,
    idComment,
    content,
    board,
    id,
    file,
    media
) => {
    let mediaIds;

    if (file) {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        const uploadFile = await fetch(`${backendUrl}/api/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${key}` },
        });

        const uploadRes = await uploadFile.json();

        mediaIds = Array.isArray(uploadRes)
            ? uploadRes.map((file) => file.id)
            : [];
        if (media) {
            media = media.map((e) => e.id);
            mediaIds = mediaIds.concat(media);
        }
    } else {
        mediaIds = media.map((e) => e.id);
    }

    let data = {
        idComment,
        content,
        mediaIds,
    };
    let reqOptions = {
        url: `${backendUrl}/api/comment/${id}?board=${board}`,
        method: "PUT",
        headers: headersJsonType(),
        data,
    };
    let response = await axios.request(reqOptions);

    return response;
};

export const deleteCreative = async (id, creative) => {
    let reqOptions = {
        url: `${backendUrl}/api/offer-service/deleteCreative/${id}?creative=${creative}`,
        method: "DELETE",
        headers: headersJsonType(),
    };
    let response = await axios.request(reqOptions);
    return response;
};
// /3.0/admin/offer/{id}/remove-creative -- creatives[]=1

export const postElemento = async (dataForm, board) => {
    let bodyContent = {};

    if (board === "/validaciones-cierre-aluna") {
        bodyContent.table = dataForm.table;
    }

    let reqOptions = {
        url: `${backendUrl}/api${board}/post`,
        method: "POST",
        headers: headersJsonType(),
        data: JSON.stringify(bodyContent),
    };

    try {
        let response = await axios.request(reqOptions);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const deleteRow = async (id, pathname) => {
    const board = pathname.slice(1);

    let reqOptions = {
        url: `${backendUrl}/api/item-service/delete?board=${board}`,
        method: "PUT",
        headers: headersJsonType(),
        data: { ids: id },
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const postActivityLogFiles = async (
    id,
    pathname,
    fieldName,
    actualValue,
    actionType
) => {
    const board = pathname.split("/")[1];
    const bodyContent = { fieldName, actualValue, actionType };

    let reqOptions = {
        url: `${backendUrl}/api/activityFiles/${id}?board=${board}`,
        method: "POST",
        headers: headersJsonType(),
        data: bodyContent,
    };
    let response = await axios.request(reqOptions);
    return response;
};

export const getStatus = async (id, board) => {
    let reqOptions = {
        url: `${backendUrl}/api/offer-service/status/${id}?board=${board}`,
        method: "GET",
        headers: headersJsonType(),
    };
    let response = await axios.request(reqOptions);
    return response.data;
};

export const getGroupById = async (id, board) => {
    let reqOptions = {
        url: `${backendUrl}/api/offer-service/getGroupById/${id}?board=${board}`,
        method: "GET",
        headers: headersJsonType(),
    };
    let response = await axios.request(reqOptions);
    return response.data;
};

export const fetchDataByPathname = async (pathname, apiService) => {
    let resSources, resCategories;
    let header = headersList();
    switch (pathname) {
        case "/solicitud-campana":
            resSources = await fetchData(`${apiService}/getSources`, header);
            resCategories = await fetchData(
                `${apiService}/getCategories`,
                header
            );

            return {
                trafficSources: sortAlphabetically(resSources, "title"),
                categories: sortAlphabetically(resCategories, "title"),
            };
        case "/info-trafico-afiliado":
            resSources = await fetchData(`${apiService}/getSources`, header);

            return {
                trafficSources: sortAlphabetically(resSources, "title"),
            };
        default:
            return [];
    }
};

export const editTagRoundler = async (tagId, data) => {
    const reqOptions = {
        url: `${backendUrl}/api/master-tag-roundler/editplacetag/${tagId}`,
        method: "PUT",
        headers: headersList(),
        data,
    };
    try {
        const response = await axios.request(reqOptions);

        if (response.status === 200) {
            return {
                success: true,
                data: response.data,
                message: "Editado exitosamente",
            };
        } else {
            return { success: false, data: null, message: "Error al editar" };
        }
    } catch (error) {
        console.error("Error updating tag content:", error);
        throw error;
    }
};

export const createTagRoundler = async (tagId, data) => {
    const reqOptions = {
        url: `${backendUrl}/api/master-tag-roundler/postnewtag/${tagId}`,
        method: "PUT",
        headers: headersList(),
        data,
    };

    try {
        const response = await axios.request(reqOptions);
        return response.data;
    } catch (error) {
        console.error("Error updating tag content:", error);
        throw error;
    }
};

export const duplicateRowsValidaciones = async (data) => {
    const reqOptions = {
        url: `${backendUrl}/api/validaciones-cierre-aluna/duplicateRows`,
        method: "POST",
        headers: headersList(),
        data,
    };

    try {
        const response = await axios.request(reqOptions);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export const duplicateRowsMasterBranding = async (data) => {
    const reqOptions = {
        url: `${backendUrl}/api/master-branding/duplicateRows`,
        method: "POST",
        headers: headersList(),
        data,
    };

    try {
        const response = await axios.request(reqOptions);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export const getProgressStatusQueue = async (query) => {
    const reqOptions = {
        url: `${backendUrl}/api/jobs/${query}`,
        method: "GET",
        headers: headersList(),
    };

    try {
        const response = await axios.request(reqOptions);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};
